<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <card>
            <div class="col-12 row">
              <div class="col-lg-6">
                <h4 class="card-title mt-4">{{ $t("Documentos") }}</h4>
                <!--<button @click="addPathtoDB()" v-if="user.rol_id == 1">{{user.name}}</button>-->

                <div v-if="this.checkedItems.length >=1">
                  <button @click="onDeleteAllFile" type="button" class="btn-file btn mb-0 btn-light text-secondary">
                    <i class="fas fa-trash mr-1"></i>
                    {{$t("Eliminar")}}
                  </button>
                </div>
              </div>
              <div class="col-lg-6 Filters mb-1">
                <div class="row">
                  <div class="col-12 font14">
                    {{ $t("FiltrosBusq") }}
                    <hr class="mt-1" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <label for="">
                      {{ $t("file_name") }}
                    </label>
                  </div>
                  <div class="col-lg-8">
                    <!-- onSearchTable -->
                    <div class="mr-3 d-flex">
                      <input type="text" v-model="filter" @keyup="onSearch" class="form-control rounded-left rounded-0 border-muted" />
                      <span class="input-group-append">
                        <div class="input-group-text rounded-right rounded-0 bg-white border-left-0 border-muted">
                          <i class="fas fa-search"></i>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="documentList">
              <l-table
                  class="table-hover table-striped"
                :columns="columnsUser"
                :section="section"
                :urlDownload="urlDownload"
                @onCheckItem="onCheckItem"
                :data="tableData">
              </l-table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiCallList } from "../../mixins";
import {mapGetters} from "vuex";

export default
{
  computed: {
    ...mapGetters({
      user: 'login/user',
    })
  },
  mixins: [apiCallList],
  data ()
  {
    return {
      endpoint: "client_documents",
      section: "/admin/documentos",
      urlDownload: "/document/download/",
      columnsUser: ["check_all", "file_type", "file_name", "user", "updated_at","path", "show_file", "download_file"],
      showList : true,
      tableData: [],
      filter: "",
      dataTable: null,
      statusFilter: "all",
      checkedItems: [],
      CheckedTypes: [],
      validItem : {},
      statusValid : false
    }
  },
  methods:
  {
    onDeleteAllFile() {
      console.log(this.checkedItems);
    },
    loadItems ()
    {
      console.log("loadItems");
      this.data.forEach ((element) => {
        element.file_name = element.name;
        element.download_file = element.url;
        element.path = element.folder_code;
      });
      this.tableData = this.data;
      console.log(this.tableData);
      this.LoadDataTable ();
    },
    LoadDataTable ()
    {
      setTimeout (() => {
        this.dataTable = window.$(".table").dataTable ({
          dom: "Bfrtip",
          buttons: [
              /*{
              text: 'Delete',
              action: function (e) {
                console.log("delete");
                console.log(e);
              }
            },*/
            {
              extend: "excelHtml5",
              title: "Documentos",
              text:
                '<i class="fas fa-file-excel text-success" title="Excel"></i>',
              exportOptions: {
                columns: [1, 2, 3, 4, 5],
              }
            },
            {
              extend: "pdfHtml5",
              text: '<i class="fas fa-file-pdf text-danger" title="PDF"></i>',
              orientation: "landscape",
              title: "Documentos",
              exportOptions: {
                columns: [1, 2, 3, 4, 5],
              },
              customize: function (doc)
              {
                doc.content [1].table.widths = "*";
                doc.styles.tableHeader.alignment = "center";
              }
            },
            {
              extend: "print",
              title: "Documentos",
              text: '<i class="fas fa-print" title="Imprimir"></i>',
              exportOptions: {
                columns: [1, 2, 3, 4, 5],
              }
            }
          ],
          pagingType: "full_numbers",
          pageLength: 25,
          language:
          {
            url: "//cdn.datatables.net/plug-ins/1.10.21/i18n/Spanish.json",
          }
        });
      }, 100);
    },
    onValidItem (type_id)
    {
      if (this.CheckedTypes.includes (type_id)) this.validItem [type_id] = {status : true}
      else if (!this.validItem [type_id]) this.validItem [type_id] = {status : this.statusValid}
      if (!this.CheckedTypes.includes (type_id)) this.statusValid = true;
      return this.validItem [type_id].status;
    },
    addPathtoDB()
    {
      this.$store.dispatch ("isLoadingOn");
      this.$axios ("/document/adding_paths")
        .then ((response) => {
          this.data = response.data;
          console.log(this.data);
          this.$store.dispatch ("isLoadingOff");
        })
        .catch(() => {
          this.$store.dispatch ("isLoadingOff");
        });

    },
    onSearchFilter ()
    {
      this.$store.dispatch ("isLoadingOn");
      this.dataTable.fnDestroy ();
      this.$axios ("/client_documents")
        .then ((response) => {
          this.data = response.data;
          console.log(this.data);
          this.data.forEach ((element) => {
            element.file_name = element.name;
            element.download_file = element.url;
          });
          this.tableData = this.data;
          this.LoadDataTable ();     
          this.$store.dispatch ("isLoadingOff");
        })
        .catch(() => {
          this.$store.dispatch ("isLoadingOff");
        });
    },
    onSearch ()
    {
      this.dataTable.fnFilter (this.filter);
    },
    onCheckItem (items)
    {
      this.checkedItems = items;
    },
    loadTemplateDocumentation ()
    {
      this.$axios ("/template_request_documentation").then ((response) => {
        this.templates = response.data;
      });
    },
    convertCurrency (amount)
    {
      return new Intl.NumberFormat ('de-DE', { style: 'currency', currency: 'EUR' }).format (amount);
    }
  }
};
</script>

<style>
  fieldset[disabled]
  {
    background: #f7f7f7;
  }
  fieldset[disabled] .e-input
  {
    background: #f8f8f8 !important;
  }
  .text-blue
  {
    color: #007bff !important;
  }
  .bg-gray
  {
    background: #f0f0f0;
  }
  .buttons
  {
    margin-top: 35px;
  }
  .ghost
  {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .titleColor
  {
    color: #6674c3;
  }
  .table > thead > tr > th
  {
    color: #535353;
    padding: 15px 10px;
  }
  .table.table thead
  {
    /*background: #c7e1f8;*/
    padding: 15px;
  }
  .documentList td:nth-child(2)
  {
    text-transform: uppercase;
  }
  .dataTables_filter
  {
    display: none;
  }
  .Filters .form-group
  {
    margin-bottom: 0px;
  }
  .e-input[disabled]
  {
    background: #f8f8f8 !important;
  }
  .rotate90
  {
    transform: rotate(90deg);
    width: 60px;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 2px;
    top: 70px;
  }
  .step-circle
  {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>